import styled from 'styled-components';
import LSTVButton from '~/components/LSTVButton';
import LSTVLink from '~/components/LSTVLink';

const commonStyles = <Component extends typeof LSTVLink | typeof LSTVButton>(Component: Component) => styled(
  Component
)<{ $selected?: boolean }>`
  border-radius: 100px;
  ${(props) => props.$selected && `background: ${props.theme.highlight_7_0}; color: #fff;`}
  ${(props) => !props.$selected && `background: transparent;`}
  border: 1px solid ${(props) => (props.$selected ? `transparent` : `#F0F2F5`)};
  cursor: pointer;
  white-space: nowrap;
  padding: 7px 11px;
  font-size: 15px;
`;

export const ChipLink = styled(commonStyles(LSTVLink))`
  text-decoration: none;
`;

export const ChipButton = styled(commonStyles(LSTVButton))`
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: inherit;
  }
`;
