import styled from 'styled-components';
import React from 'react';
import LSTVButton from '../LSTVButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

export interface HorizontalScrollButtonsProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * This element will be scrolled to.
   */
  selectedElement?: HTMLElement;
}

const buttonWidth = 67;

const Container = styled.div`
  position: relative;
  display: flex;
`;

const ScrollableContainer = styled.div`
  overflow-x: auto;
  display: flex;
  align-items: center;
  height: 100%;

  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const StyledButton = styled(LSTVButton)<{ $visible: boolean; $right?: boolean }>`
  position: absolute;
  width: ${buttonWidth}px;
  height: 100%;
  top: 0;
  ${(props) => (props.$right ? 'right' : 'left')}: 0;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: opacity 0.3s linear;
  ${(props) => !props.$visible && 'pointer-events: none;'};
  font-size: 19px;
  cursor: pointer;
  background: linear-gradient(
    ${(props) => (props.$right ? `270deg` : `90deg`)},
    #ffffff 0%,
    #ffffff 41.51%,
    rgba(255, 255, 255, 0.71) 109.18%
  );
`;

const buttonsEnabled = typeof window !== 'undefined' && !window.matchMedia('(hover: none)').matches;

const scrollLeftOrRight = (container: Element | null, right?: boolean) => {
  if (container) {
    const effectiveButtonWidth = buttonsEnabled ? buttonWidth : 0;
    const anchorElement = (right ? [...container.childNodes] : [...container.childNodes].reverse()).find(
      (el) =>
        el instanceof HTMLElement &&
        (right
          ? el.offsetLeft + el.offsetWidth > container.scrollLeft + container.clientWidth - effectiveButtonWidth
          : el.offsetLeft < container.scrollLeft + effectiveButtonWidth)
    ) as HTMLElement | undefined;
    if (anchorElement) {
      container.scrollTo({
        left: (() => {
          if (right) {
            return anchorElement.offsetLeft - effectiveButtonWidth;
          } else {
            const minRequiredScroll =
              anchorElement.offsetLeft + anchorElement.offsetWidth - container.clientWidth + effectiveButtonWidth;
            const leftMostElement = [...container.childNodes].find(
              (el) => el instanceof HTMLElement && el.offsetLeft >= minRequiredScroll + effectiveButtonWidth
            ) as HTMLElement | undefined;
            return leftMostElement ? leftMostElement.offsetLeft - effectiveButtonWidth : minRequiredScroll;
          }
        })(),
        behavior: 'smooth',
      });
    }
  }
};

/**
 * A container with arrow buttons on the sides for scrolling horizontally. On
 * touch-only devices (`window.matchMedia('(hover: none)').matches`) no buttons
 * are shown and this is just a scrollable div.
 */
// eslint-disable-next-line react/display-name
const HorizontalScrollButtons = ({ onScroll, selectedElement, children, ...rest }: HorizontalScrollButtonsProps) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [buttonsRendered, setButtonsRendered] = React.useState(false);
  const [leftButtonVisible, setLeftButtonVisible] = React.useState(false);
  const [rightButtonVisible, setRightButtonVisible] = React.useState(false);

  const updateButtonsVisibility = (container: HTMLDivElement) => {
    setLeftButtonVisible(container.scrollLeft > 0);
    setRightButtonVisible(container.scrollLeft + container.clientWidth < container.scrollWidth - 1);
  };

  React.useEffect(() => {
    const container = containerRef.current;
    if (container && selectedElement) {
      const effectiveButtonWidth = buttonsEnabled ? buttonWidth : 0;
      // If we need to scroll to the left...
      if (selectedElement.offsetLeft < container.scrollLeft + effectiveButtonWidth) {
        // If we need to scroll all the way to the left so that the arrow button gets hidden...
        if (selectedElement.offsetLeft < effectiveButtonWidth) {
          container.scrollLeft = 0;
        } else {
          container.scrollLeft = selectedElement.offsetLeft - effectiveButtonWidth;
        }
      } else if (
        selectedElement.offsetLeft + selectedElement.offsetWidth >
        container.scrollLeft + container.clientWidth - effectiveButtonWidth
      ) {
        if (selectedElement.offsetLeft + selectedElement.offsetWidth > container.scrollWidth - effectiveButtonWidth) {
          container.scrollLeft = container.scrollWidth - container.clientWidth;
        } else {
          container.scrollLeft =
            selectedElement.offsetLeft + selectedElement.offsetWidth - container.clientWidth + effectiveButtonWidth;
        }
      }
      setButtonsRendered((buttonsRendered) => {
        if (buttonsEnabled) {
          if (!buttonsRendered) {
            // This also gets called in onScroll callback, but we want to make sure this
            // gets called before the buttons are rendered for the first time, so that there
            // is no fade-in animation.
            updateButtonsVisibility(container);
          }
          return true;
        } else {
          return false;
        }
      });
    }
  }, [selectedElement]);

  return (
    <Container {...rest}>
      <ScrollableContainer
        ref={containerRef}
        onScroll={(event) => {
          updateButtonsVisibility(event.currentTarget);
          return onScroll?.(event);
        }}
      >
        {children}
      </ScrollableContainer>
      {buttonsRendered && (
        <>
          <StyledButton
            $visible={leftButtonVisible}
            aria-label="Scroll to the left"
            onClick={() => scrollLeftOrRight(containerRef.current)}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </StyledButton>
          <StyledButton
            $visible={rightButtonVisible}
            $right
            aria-label="Scroll to the right"
            onClick={() => scrollLeftOrRight(containerRef.current, true)}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </StyledButton>
        </>
      )}
    </Container>
  );
};

export default HorizontalScrollButtons;
