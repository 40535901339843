import React from 'react';
import styled from 'styled-components';
import { ChipButton } from '~/components/Chip';
import HorizontalScrollButtons from '~/components/HorizontalScrollButtons';
import { maxWidth } from '~/styles/media';

export interface ChipsSelectProps<Option> extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
	options: Option[];
	value?: Option;
	onChange: (value: Option) => void;
}

const StyledHorizontalScrollButtons = styled(HorizontalScrollButtons)`
  @media ${maxWidth('md')} {
    margin-left: -16px;
    margin-right: -16px;
  }
`;

const StyledChipButton = styled(ChipButton)`
  border-radius: 100px;
  ${(props) => props.$selected && `background: ${props.theme.highlight_7_0}; color: #fff;`}
  ${(props) => !props.$selected && "background: transparent;"}
  border: 1px solid ${(props) => (props.$selected ? "transparent" : "#F0F2F5")};
  cursor: pointer;
  white-space: nowrap;
  height: 36px;
  padding: 0 11px;
  font-size: 15px;

  & + & {
    margin-left: 9px;
  }

  @media ${maxWidth('md')} {
    &:first-child {
      margin-left: 16px;
    }
    &:last-child {
      margin-right: 16px;
    }
  }
`;

const ChipsSelect = <Option extends { id: string; label: string}>({
	options,
	value,
	onChange,
	...rest
}: ChipsSelectProps<Option>) => {
	const [selectedElement, setSelectedElement] = React.useState<HTMLElement | undefined>();
	return (
		<StyledHorizontalScrollButtons {...{ selectedElement }} {...rest}>
			{options.filter((o) => !!o).map((option) => {
				const selected = option.id === value?.id;
				return (
					<StyledChipButton
						key={option.id}
						ref={selected ? setSelectedElement : undefined}
						$selected={selected}
						eventAction={option.label}
						onClick={() => {
							onChange(option);
						}}
					>
						{option.label}
					</StyledChipButton>
				);
			})}
		</StyledHorizontalScrollButtons>
	);
};

export default ChipsSelect;
